<template>
  <v-menu
    ref="datePicker"
    v-model.trim="startDatePicker"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        dense
        filled
        :label="lodash.capitalize(placeholder)"
        :rules="
          mandatory
            ? [() => !!datePicker.date || placeholder + ' is required']
            : []
        "
        prepend-inner-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        :outlined="!solo"
        :solo="solo"
        :flat="solo"
        :loading="pageLoading"
        :disabled="pageLoading"
        :hide-details="!showDetails"
        :clearable="clearable"
        :value="formattedStartDate"
        color="cyan"
        v-on:click:clear="datePicker.date = null"
        :class="fieldClass"
      ></v-text-field>
    </template>
    <v-date-picker
      :disabled="pageLoading"
      v-model.trim="datePicker.date"
      color="cyan"
      v-on:input="startDatePicker = false"
      v-on:change="saveDate"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import AppConfiguration from "@/core/config/app.config";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  data: () => {
    return {
      pickerType: null,
      startDatePicker: false,
      datePicker: {
        date: new Date().toISOString().substr(0, 10),
      },
    };
  },
  props: {
    defaultDate: {
      type: String,
      default: () => {
        return new Date().toISOString().substr(0, 10);
      },
    },
    placeholder: {
      type: String,
      default: () => {
        return "Date";
      },
    },
    customClass: {
      type: String,
      default: "",
    },
    activePicker: {
      type: String,
      default: "DATE",
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
    pageLoading: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    nullable: {
      type: Boolean,
      default: false,
    },
    removeBorder: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: () => {
        return new Date().toISOString().substr(0, 10);
      },
    },
  },
  mounted() {
    if (!this.nullable) {
      this.datePicker.date = this.defaultDate || "";
      this.$emit("update:date-picker", this.datePicker.date);
      this.$emit("input", this.datePicker.date);
    } else {
      this.datePicker.date = "";
    }
  },
  methods: {
    saveDate(date) {
      this.$refs.datePicker.save(date);
    },
  },
  watch: {
    value(param) {
      if (param) {
        this.datePicker.date = param;
      }
    },
    startDatePicker(val) {
      const _this = this;
      val && setTimeout(() => (_this.pickerType = _this.activePicker));
    },
    defaultDate() {
      if (!this.nullable) {
        this.datePicker.date = this.defaultDate || "";
      }
    },
    nullable(param) {
      if (param) {
        this.datePicker.date = "";
      }
    },
    datePicker: {
      deep: true,
      immediate: true,
      handler(param) {
        this.$emit("update:date-picker", param.date);
        this.$emit("input", param.date);
      },
    },
  },
  computed: {
    fieldClass() {
      let result = this.customClass;
      if (this.removeBorder) {
        result = result + "remove-input-border";
      }
      return result;
    },
    formattedStartDate() {
      let Config = AppConfiguration.get();
      if (Config) {
        if (!this.datePicker.date) {
          if (this.nullable) {
            return null;
          }
          return moment().format(Config.dateFormat);
        }
        return moment(this.datePicker.date).format(Config.dateFormat);
      }
      return this.datePicker.date;
    },
  },
};
</script>
